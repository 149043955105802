import $ from 'jquery';
import Splitting from 'splitting';

import { $Elements, Events } from '../global/constants';

// ---------- Init Base Animation ----------

export const initBaseAnimations = () => {

	$('.links-section .link-card .text').each(function() {

		const results = Splitting({ target: this });
	});

	$('.content-section:not(.links-section) .content > *').each(function() {
		if(document.documentElement.lang === 'zh-CN') {
			const infosColumn = this.querySelector('.infos-column');

			if(infosColumn) {
				$('.wine-infos').addClass('words lines splitting');
				$('.wine-infos').wrapInner('<span class="word"></span>');
			}
			else {
				$(this).not('.wine-details').addClass('words lines splitting');
				$(this).not('.wine-details').wrapInner('<span class="word"></span>');
			}
		}
		else {
			const results = Splitting({ target: this, by: 'lines' });
		}
	});
};

$Elements.document.on(Events.page.enter, () => initBaseAnimations());
