import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

import { $Elements, Durations, Events } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel ----------

	$('.content-section .section-slideshow, .content-section .column-slideshow').each(function() {

		if(!this.swiper) {
			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				centeredSlides: true,
				spaceBetween: 20,
				slidesPerView: "auto",
				autoplay: true,
				modules: [Autoplay, Navigation, Pagination],
				pagination: {
					el: this.querySelector(".swiper-pagination"),
				},
				navigation: {
					nextEl: this.querySelector(".swiper-button-next"),
					prevEl: this.querySelector(".swiper-button-prev"),
				},
			});
		}
	});

	// ---------- Slideshow ----------

	$('.text-image-slider-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				modules: [Navigation],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});
});
