import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Events, Classes, Elements, Breakpoints } from '../global/constants';
import { elementPosition } from '../global/helpers';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Animated text-images-slider-section ---------

	$('.images-carousel-section').each(function() {

		Animations.global.push(() => {

			const content = this.querySelector(':scope .section-columns');

			if(content) {

				const columns = gsap.utils.toArray('.content-column', content);
				const contentWidth = () => (columns.reduce((val, column) => val + column.offsetWidth, 0));
				const triggerOffset = () => {
					const position = elementPosition(content);
					return position.top;
				}

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						pin: true,
						scrub: true,
						invalidateOnRefresh: true,
						start: () => triggerOffset(),
						end: () => '+=' + contentWidth(),
					},
				});

				timeline.to(content, { x: () => window.innerWidth - contentWidth(), ease: "none" });
			}
		});
	});

	// ---------- Menu Anchors Pin ----------

	$('#menu-anchors-section').each(function() {

		Animations.mobile.min.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top top',
					endTrigger: Elements.FooterWrapper,
					end: 'bottom top',
					pin: true,
					pinSpacing: false,
				}
			});
		});
	});

	// ---------- Parallax Items Section ----------

	$('.parallaxed-text').each(function() {

		Animations.sm.min.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top bottom',
					end: 'bottom top',
					scrub: true,
					invalidateOnRefresh: true,
				},
			});

			const image = this.querySelectorAll('.image-column .image, .section-content .image');

			if(image.length) {

				timeline.fromTo(image, {
					yPercent: -10,
				}, {
					yPercent: 10,
					ease: 'none',
				}, 0);
			}
		});
	});

	$('.text-two-unstructured-images-section').each(function() {

		let targeted_offset = -5;
		let image = this.querySelector(':scope .content-column .content .image:first-of-type');

		if(image) {

			Animations.sm.min.push(() => {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				timeline.fromTo(image, {
					xPercent: targeted_offset * 2
				}, {
					xPercent: -2,
					ease: 'none',
				}, 0);

			});
		}
	});


	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});

		if(this.classList.contains('parallax-content')) {

			Animations.global.push(() => {

				const content = this.querySelector(':scope .section-content');
				const speed = parseInt(this.dataset.speed, 10) + 10;

				if(content) {

					const timeline = gsap.timeline({
						scrollTrigger: {
							trigger: this,
							start: 'top bottom',
							end: 'bottom top',
							scrub: true,
							invalidateOnRefresh: true,
						},
					});

					const offset = () => content.offsetHeight - ((content.offsetHeight - this.offsetHeight) / 2);

					timeline.fromTo(
						content,
						{ y: () => offset() * (speed / 100), ease: 'none' },
						{ y: () => offset() * (-speed / 100), ease: 'none' }
					);
				}
			});
		}
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});

			gsap.timeline({
				scrollTrigger: {
					start: -1,
					end: 10,
					toggleClass: {
						targets: document.body,
						className: 'on-top'
					},
				}
			});

		});
	});

	// ---------- Header ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			gsap.timeline({
				scrollTrigger: {
					start: 1,
					end: 'max',
					onUpdate: (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}
				}
			});
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
